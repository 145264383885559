<template>
  <div class="pbf">
     
     <BrandTabGrowers
      :inurl="'/products/' + props.id + '/growers'"    
      :product-id="props.id"  
      />

  </div>
</template>

<script setup>
 
const {$api, $tagsUtil, $ga} = useNuxtApp()

const props = defineProps({
  id: {
    type: Number
  }
})

</script>

<style scoped>
 

</style>
